import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import ProductDetail from "./ProductDetail";
import NotFound from "./NotFound";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the HomePage */}
        <Route path="/" element={<HomePage />} />

        {/* Route for individual Product Detail page */}
        <Route path="/product/:slug" element={<ProductDetail />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
